
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BgCoverIt Module

(function ($) {

    "use strict";

    var self,
        $bgCoverIt;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BgCoverIt() {

        self        = this;
        $bgCoverIt  = $('[data-bg-cover-it]');

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BgCoverIt.prototype.init = function () {

        $.each( $bgCoverIt, function(index, val) {

            var $img = $(val).find('img'),
                img_src = $img.attr('src');

            $img.remove();

            $(val).css('background-image', 'url("'+img_src+'")');

        });

    };

    this.BgCoverIt = new BgCoverIt();

}.bind(Deckperfo, jQuery)());


/*
 * Scroll Script
 * ===========================================
 *
 */
$(document).ready(function() {

    $( "a.scroll" ).click(function(e) {
        var target =  $( this ).attr( 'href' ).substr( $( this ).attr( 'href' ).indexOf( '#' ) );
        if( $( target ).length ) {
            e.preventDefault();
            $('html,body').animate({
              scrollTop: $( target ).offset().top - 70
            }, 1000);
        }
    });

});

/**
 * SCRIPT GoTo();
 * ===========================================
 *
 */
$(document).ready(function() {

  'use strict';

    function init(){

        initEvents();

    }

    function initEvents(){

        /*goTo*/
        $( '[data-goto]' ).on( 'click', function(){

            var nextSection = $( this ).attr( 'data-goto' );

            $( 'html, body' ).animate({
                scrollTop: $( '#'+nextSection ).offset().top
            }, 855);

            return false;

        });

        $.each( $('[data-goto]'), function(index, val) {
            var section = $(this).data('goto');

            new Waypoint({
              element: document.getElementById( section ),
              handler: function(direction) {
                $('[data-goto]').removeClass('active');
                $('[data-goto="'+ section +'"]').addClass('active');
              }

            })

        });

        $(window).scroll(function(){
            if( $(window).scrollTop() <= 50 ) {
                $('[data-goto]').removeClass('active');
            }
        });

    }

  init();

});




