
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MainNav1 Module

(function ($) {

    "use strict";

    var self,
        $nav,
        buffer,
        lastScrollTop;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function MainNav1() {

        self = this;
        $nav = $('#main-nav');
        buffer = 10;
        lastScrollTop = 0;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    MainNav1.prototype.init = function () {

        this.checkIfFollow();
        this.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    MainNav1.prototype.addEventHandler = function () {

        self = this;

        $(window).scroll(function(){

            self.checkIfFollow();

            var st = $(this).scrollTop();

            if (st > lastScrollTop){
              // downscroll code
              $nav.addClass( "hide-nav-bar" );
            } else if( st < ( lastScrollTop - buffer ) ){
              // upscroll code
              $nav.removeClass( "hide-nav-bar" );
            }

            if(st <= 15){
              $nav.removeClass( "hide-nav-bar" );
            }

            lastScrollTop = st;

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    MainNav1.prototype.checkIfFollow = function () {

        self = this;

        var st = $(window).scrollTop();

        if( st >= 150 ){
            $nav.addClass('follow');
        }else{
            $nav.removeClass('follow');
        }

    };

    this.MainNav1 = new MainNav1();

}.bind(Deckperfo, jQuery)());


/* Menu fix */
  
$(window).scroll(function () { 

    var scrollTop =  $(window).scrollTop();

    if( scrollTop >= $( '#hero').outerHeight() ) {
        $('body').addClass('navbar-fixed');
    }
    else {
        $('body').removeClass('navbar-fixed');    
    }

});


/**
 * Burger Navigation
 * ===========================================
 *
 */

// Namespacing

$(document).ready(function() {

    window.Deckperfo = window.Deckperfo  || {};

    // Burger nav menu

    (function ($) {

        "use strict";

        var self,
            burger,
            nav,
            nav2,
            social,
            overlay;

        // ------------------------------------
        // CONSTRUCTOR
        // ------------------------------------

        function BurgerNav() {

            self    = this;
            burger  = '.menu';
            nav     = '#navbar';
            nav2    = '#secondary-nav';
            social  = '.social-section';
            overlay = '#nav-links-overlay';

        }

        // ------------------------------------
        // INIT
        // ------------------------------------

        BurgerNav.prototype.init = function () {

            self = this;

            self.addEventHandler();

        };

        // ------------------------------------
        // EVENT HANDLERS
        // ------------------------------------

        BurgerNav.prototype.addEventHandler = function () {

            self = this;

            $(burger).on('click', function(event) {
                event.preventDefault();
                $(nav).toggleClass('active');
                $(nav2).toggleClass('active');
                $(social).toggleClass('active');
                $(overlay).toggleClass('active');
                $(burger).toggleClass('active');
            });

            $(nav).on('click', function(event) {
                if($(this).hasClass('active')){
                    $(nav).removeClass('active');
                    $(nav2).removeClass('active');
                    $(social).removeClass('active');
                    $(overlay).removeClass('active');
                    $(burger).removeClass('active');
                }
            });

            $(overlay).on('click', function(event) {
                event.preventDefault();
                $(nav).removeClass('active');
                $(nav2).removeClass('active');
                $(social).removeClass('active');
                $(overlay).removeClass('active');
                $(burger).removeClass('active');
            });

        };

        // ------------------------------------
        // METHODS
        // ------------------------------------

        this.BurgerNav = new BurgerNav().init();

    }.bind(Deckperfo, jQuery)());

});
