
// Namespacing

window.Deckperfo = window.Deckperfo || {};

// Launch modules

;(function ($) {

  'use strict';

  var app = this;

  $(document).ready(function () {

    // Global js pluggins
    // app.BgCoverIt.init();

    // Component's core js modules
    app.MainNav1.init();
    //app.BlocStatistics1.init();

  });


}.bind(Deckperfo, jQuery)());
